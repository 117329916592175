<template>
  <div>
    <v-app-bar
      id="app-bar"
      app
      dense
      flat
      :class="$vuetify.theme.dark ? 'dark-top-bar' : 'white'"
    >
      <v-toolbar-title>
        {{ $t(sectionTitle.title) }}
      </v-toolbar-title>

      <v-spacer />

      <v-menu
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            {{ authShareholder.username }}
            <v-icon>mdil-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="goToProfile()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                {{ $t('Profile') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="setLtr()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                English
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="setRtl()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                کوردی
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
          <v-list-item @click="changeTheme()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                {{ $t('Dark mode') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
          <v-list-item @click="logoutUser()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon color="error">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title class="error--text">
                {{ $t('Logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'sectionTitle',
      'authShareholder',
      'companiesNotification',
    ])
  },

  mounted() {
    this.$_section_title({ title: 'Home' })

    window.addEventListener('scroll', () => {
      const appBar = document.getElementById('app-bar')
      if (
        document.body.scrollTop > 120 ||
        document.documentElement.scrollTop > 120
      ) {
        appBar.classList.add('bar-box-shadow')
      } else {
        appBar.classList.remove('bar-box-shadow')
      }
    });
  },

  methods: {
    ...mapActions([
      'toggleRtl',
      'checkCompaniesNotification',
      'toggleTheme',
      'setLocale',
      'logout'
    ]),

    goToProfile() {
      this.$router.push({ name: 'client-profile' })
    },

    logoutUser() {
      this.logout().then(() => {
        this.$router.push('/')
      })
    },

    setRtl() {
      this.$vuetify.rtl = true
      this.$i18n.locale = 'ckb'
      this.toggleRtl(true)
      this.setLocale('ckb')
    },

    setLtr() {
      this.$vuetify.rtl = false
      this.$i18n.locale = 'en'
      this.toggleRtl(false)
      this.setLocale('en')
    },

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.toggleTheme(this.$vuetify.theme.dark)
    }
  },
}
</script>

<style lang="scss" scoped>
.dark-top-bar {
  background-color: #1e1e1e !important;
}
</style>